<!--
/*
 * @Author:张天鹏
 * @Date: 2020-03-07 15:03:31
 * @LastEditors: 刘旭
 * @LastEditTime: 2020-07-06 16:18:48
 */
 -->
<template>
  <div class="mainbody">
    <!-- <div class="orderformtop"> -->
    <div>
      <selectSearch
        :selectList="selectList"
        @clickSearch="search"
        :inputKey="inputValue"
        :selectKey="selectorValue"
      ></selectSearch>
      <div
        class="list"
        :style="{'height': (reportList.length ? '85vh' :'100vh')}"
      >
        <md-scroll-view
          ref="scrollView"
          v-show="!isNull"
          auto-reflow
          :scrolling-x="false"
          @end-reached="loadMorechange"
        >
          <div class="scroll">
            <div class="product" v-for="(item,index) of reportList" :key="index">
              <div>
                <div class="producttop">
                  <div class="producttopl">
                    <p class="omit">
                      <!-- 发票申请 -->
                      <span class="bdflag" v-if="item.orderType==='2'&&pageType==='3'">
                        <span>保单</span>
                      </span>
                      <span class="pdflag bdflag" v-if="item.orderType==='3'&&pageType==='3'">
                        <span>批单</span>
                      </span>
                      {{item.prodName}}
                    </p>
                  </div>
                  <!-- 核保
                  <div class="producttopr hb" v-if="pageType==='1'">
                    <p>
                      <span>已提交</span>
                    </p>
                  </div>-->
                  <!-- 退保 -->
                  <div class="producttopr tb" v-if="pageType==='2'">
                    <p v-if="item.orderStatus==='6'">
                      <span>生效中</span>
                    </p>
                    <p v-if="item.orderStatus==='7'">
                      <span>退保成功</span>
                    </p>
                  </div>
                  <!-- 保单发票 -->
                  <div class="producttopr bdfp" v-if="pageType==='3'&&item.orderType==='2'">
                    <p v-if="item.invoiceStatus==='3'">
                      <span>申请成功</span>
                    </p>
                    <p v-if="item.invoiceStatus==='1'||item.invoiceStatus==='6'">
                      <span>已申请</span>
                    </p>
                    <p v-if="item.invoiceStatus==='2'||item.invoiceStatus==='5'">
                      <span>申请失败</span>
                    </p>
                    <p v-if="item.invoiceStatus==='4'">
                      <span>已取消</span>
                    </p>
                  </div>
                  <!-- 批单发票 -->
                  <div class="producttopr pdfp" v-if="pageType==='3'&&item.orderType==='3'">
                    <p v-if="item.invoiceStatus==='3'">
                      <span>申请成功</span>
                    </p>
                    <p v-if="item.invoiceStatus==='1'||item.invoiceStatus==='6'">
                      <span>已申请</span>
                    </p>
                    <p v-if="item.invoiceStatus==='2'||item.invoiceStatus==='5'">
                      <span>申请失败</span>
                    </p>
                    <p v-if="item.invoiceStatus==='4'">
                      <span>已取消</span>
                    </p>
                  </div>
                </div>
                <div class="orderContent">
                  <div class="productcen">
                    <div class="productcon" v-if="pageType==='1'">
                      <div class="productconl">
                        <p>订单号：{{item.channelOrderCode}}</p>
                      </div>
                    </div>
                    <div class="productcon" v-else>
                      <div class="productconl">
                        <p>保单号：{{item.compPlcNo}}</p>
                      </div>
                    </div>
                    <div class="productcon" v-if="pageType==='3'&&item.orderType==='3'">
                      <div class="productconl">
                        <p>批单号：{{item.correctNo}}</p>
                      </div>
                    </div>
                    <div class="productcon" v-if="item.invoiceNo">
                      <div class="productconl">
                        <p>发票号：{{item.invoiceNo}}</p>
                      </div>
                    </div>
                    <div class="productcon">
                      <div class="productconl">
                        <p>投保人：{{item.paidName}}</p>
                      </div>
                    </div>
                    <div class="productcon">
                      <div class="productconl">
                        <p>被保人：{{item.ipaidName}}</p>
                      </div>
                    </div>
                    <!-- <div class="productcon">
                    <div class="productconl">
                      <p>出单人：{{item.empname}}</p>
                    </div>
                    </div>-->
                    <div class="productcon">
                      <div class="productconl">
                        <p>投保时间：{{item.geneTime}}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="lookOrder lookpd"
                    :class="item.invoiceNo?'lookpdfp':''"
                    @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,5,item.orderType)"
                    v-if="pageType==='3'&&item.orderType==='3'"
                  >查看订单</div>
                  <div
                    class="lookOrder"
                    :class="item.invoiceNo?'lookfp':''"
                    v-else
                    @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,5,item.orderType)"
                  >查看订单</div>
                </div>
              </div>
              <div class="productbom">
                <!-- 核保 -->
                <div class="productbomFrist" v-if="pageType==='1'">
                  <div class="totalMount" v-if="item.orderStatus==='2'">
                    <div class="productbomInfo">核保结果：等待审核</div>
                  </div>
                  <div class="totalMount" v-else-if="item.orderStatus==='3'">
                    <div class="productbomInfo">核保结果：核保失败</div>
                  </div>
                  <div class="totalMount" v-else>
                    <div class="productbomInfo">核保结果：核保成功</div>
                  </div>
                  <div class="showBtn">
                    <div class="productbomr" v-if="item.orderStatus==='3'">
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,7,item.orderType)"
                      >修改</md-button>
                    </div>
                  </div>
                </div>
                <!-- 退保 -->
                <div class="productbomFrist" v-if="pageType==='2'">
                  <div class="totalMount">
                    <div class="productboml">
                      <span class="wfont">
                        总保费:
                        <span class="mondyUnit">￥</span>
                        <span class="hfont">{{item.premium}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="showBtn" v-if="item.orderStatus==='6'">
                    <div class="productbomr">
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="surrender(item.channelOrderCode,item.correctNo,item.baseid,4,item.orderType)"
                      >退保</md-button>
                    </div>
                  </div>
                </div>
                <!-- 发票申请 -->
                <div class="productbomFrist" v-if="pageType==='3'">
                  <div class="totalMount">
                    <div class="productboml">
                      <span class="wfont">
                        总保费:
                        <span class="mondyUnit">￥</span>
                        <span class="hfont">{{item.premium}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="showBtn">
                    <div class="productbomr" v-if="item.orderType==='2'">
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,5,'fp',item.invoiceNo)"
                        v-if="item.invoiceStatus==='3'"
                      >查看发票</md-button>
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,7,'fp',item.invoiceNo)"
                        v-else-if="item.invoiceStatus==='5'"
                      >修改发票</md-button>
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,4,'fp',item.invoiceNo)"
                        v-else-if="item.invoiceStatus==='1'"
                      >撤销申请</md-button>
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,1,'fp')"
                        v-else-if="item.invoiceStatus!=='6'"
                      >申请发票</md-button>
                    </div>
                    <div class="productbomr fpsq" v-if="item.orderType==='3'">
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,5,'fp',item.invoiceNo)"
                        v-if="item.invoiceStatus==='3'"
                      >发票查看</md-button>
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,7,'fp',item.invoiceNo)"
                        v-else-if="item.invoiceStatus==='5'"
                      >修改发票</md-button>
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,4,'fp',item.invoiceNo)"
                        v-else-if="item.invoiceStatus==='1'"
                      >撤销申请</md-button>
                      <md-button
                        class="delet"
                        size="small"
                        round
                        @click="getCoopCall(item.channelOrderCode,item.correctNo,item.baseid,1,'fp')"
                        v-else-if="item.invoiceStatus!=='6'"
                      >发票申请</md-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <md-scroll-view-more slot="more" :is-finished="listFinished" />
        </md-scroll-view>
        <div class="nodata" v-show="isNull">
            <img class="kongimg" src="@/assets/abd/image/null.png" alt />
            <p class="asdasdqwe">目前还没有内容哦~</p>
        </div>
      </div>
    </div>
    <md-popup v-model="isPopupShow" :mask-closable="closable">
      <div class="icdoling">
        <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
      </div>
    </md-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from "mand-mobile";
import {
  getCoopOrderlist,
  coopMyPage,
  channelCoopCall
} from "@/api/xsqd/index";
import loadMorechange from "@/mixins/loadMorechange";
import { getStorage } from "@/lib/util";
// import loadMore from "@/mixins/loadmore";
import selectSearch from "../../components/select";
export default {
  mixins: [loadMorechange],
  components: {
    selectSearch
  },
  data() {
    return {
      closable: false,
      isPopupShow: true,

      isNull: false,
      scrollTop: 0,
      // listpageNo: 1,
      // pageSize: 6,
      listpageNo: 1,
      listsize: 10,
      listtotal: 0,
      listtotalPage: 0,
      listFinished: true,
      loadflag: false,
      height: "",

      loading: false,
      reportList: [],
      listtotalPage: 0,
      current: 1,
      isInit: false,
      user: {},
      items: [
        { name: 1, label: "待提交" },
        { name: 2, label: "待批复" },
        { name: 3, label: "未通过" },
        { name: 4, label: "已通过" }
      ],
      isActive: "R",
      selectListOne: [
        { value: 0, text: "产品名称" },
        { value: 1, text: "批单号" },
        { value: 2, text: "投保人" },
        { value: 3, text: "被保人" },
        { value: 4, text: "保单号" }
      ],
      selectListTwo: [
        //核保
        { value: 0, text: "产品名称" },
        { value: 1, text: "订单号" },
        { value: 2, text: "投保人" },
        { value: 3, text: "被保人" }
      ],
      selectListThree: [
        //退保
        { value: 0, text: "产品名称" },
        { value: 1, text: "保单号" },
        { value: 2, text: "投保人" },
        { value: 3, text: "被保人" }
      ],
      selectList: [],

      orderType: "",
      statusType: 1,
      pageType: "",
      prodName: "",
      paidName: "",
      ipaidName: "",
      compPlcNo: "",
      correctNo: "",
      channelOrderCode: "",
      inputValue: "",
      selectorValue: "产品名称"
    };
  },
  // mixins: [loadMorechange],
  created() {
    this.user = getStorage("u_s", {});
    this.pageType = this.$route.query.pageType;
    if (this.pageType === "1") {
      this.selectList = this.selectListTwo;
    }
    if (this.pageType === "2") {
      this.selectList = this.selectListThree;
    }
    if (this.pageType === "3") {
      this.selectList = this.selectListOne;
    }
    this.getData();
  },
  mounted() {},
  methods: {
    search(selectIndex, selectorValue, inputValue) {
      this.prodName = "";
      this.paidName = "";
      this.ipaidName = "";
      this.compPlcNo = "";
      this.correctNo = "";
      this.channelOrderCode = "";
      this.inputValue = inputValue;
      this.selectorValue = selectorValue;
      if (selectorValue === "产品名称") {
        this.prodName = inputValue;
      }
      if (selectorValue === "投保人") {
        this.paidName = inputValue;
      }
      if (selectorValue === "被保人") {
        this.ipaidName = inputValue;
      }
      if (selectorValue === "保单号") {
        this.compPlcNo = inputValue;
      }
      if (selectorValue === "批单号") {
        this.correctNo = inputValue;
      }
      if (selectorValue === "订单号") {
        this.channelOrderCode = inputValue;
      }
      this.listpageNo = 1;
      this.reportList = [];
      this.listFinished = false;
      this.loadflag = false;
      if (this.reportList.length !== 0) {
        this.$refs.scrollView.finishLoadMore();
      }
      this.getData();
    },
    getData() {
      let data = {
        pageType: this.pageType,
        empno: this.user.empno,
        prodName: this.prodName, //搜索条件字段
        correctNo: this.correctNo,
        compPlcNo: this.compPlcNo,
        paidName: this.paidName,
        ipaidName: this.ipaidName,
        channelOrderCode: this.channelOrderCode,
        pageNo: this.listpageNo,
        pageSize: this.listsize
      };
      this.isPopupShow = true;
      coopMyPage(data).then(res => {
        this.isPopupShow = false;
        this.reportList =
          this.reportList == []
            ? res.data.data.records
            : this.reportList.concat(res.data.data.records);
        if (this.reportList.length == 0) {
          this.isNull = true;
        } else {
          this.isNull = false;
        }
        this.listtotal = res.data.data.total;
        this.listtotalPage = res.data.data.pages;
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true;
        } else {
          this.listFinished = false;
          this.$nextTick(() => {
            this.$refs.scrollView.finishLoadMore();
          });
        }
        this.loadflag = true;
      });
    },
    surrender(v1,v2,v3,v4){
      Dialog.confirm({
        title: "退保申请",
        content: "请确认是否要退保?",
        confirmText: "确定",
        onConfirm: () => {
          this.getCoopCall(v1,v2,v3,v4)
        }
      });
    },
    getCoopCall(mg1, mg2, mg3, val, flag, invoiceNo) {
      let bizType;
      if (flag === "fp") {
        bizType = 2;
      } else if (flag === "3") {
        bizType = 3;
      } else {
        bizType = 1;
      }
      if (this.pageType === "2") {
        if (val === 4) {
          Toast.loading("退保中");
        } else {
          Toast.loading("跳转中");
        }
      } else {
        Toast.loading("跳转中");
      }
      let params = {
        bizType: bizType,
        data: {
          orderNo: mg1,
          invoiceNo: invoiceNo ? invoiceNo : "",
          correctNo: mg2
        },
        channel: mg3,
        operType: val
      };
      channelCoopCall(params)
        .then(res => {
          Toast.hide();
          if (res.data.code === 200) {
            if (res.data.data) {
              window.location.href = res.data.data;
            } else {
              if (val === 4) {
                Toast.succeed("退保成功！");
                this.listpageNo = 1;
                this.reportList = [];
                this.listFinished = false;
                this.loadflag = false;
                if (this.reportList.length !== 0) {
                  this.$refs.scrollView.finishLoadMore();
                }
                this.getData();
              }
            }
          }
        })
        .catch(err => {
          Toast.hide();
        });
    }
  }
};
</script>

<style lang="stylus" scoped>
.mainbody {
  background-color: #F7F6FB;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.orderformtop {
  padding-top: 1rem;
}

/deep/ .md-tab-bar {
  background: #fff;
}

.marginleft {
  margin-left: 10px;
}

/deep/ .md-tab-bar-ink {
  width: 0px !important;
}

/deep/ .md-tab-bar-item {
  font-size: 0.4rem;
  font-weight: bold;
  margin-top: 5px;
  color: #333333;
  min-height: 1.25rem;
}

/deep/ .is-active {
  border-bottom: 5px solid;
  color: #FF7326;
}

.omit {
  margin-left: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.4rem;
  font-weight: bold;
  color: #373737;

  .bdflag {
    display: inline-block;

    span {
      display: inline-block;
      width: 0.83rem;
      height: 0.4rem;
      line-height: 0.43rem;
      text-align: center;
      background: #FF7E3A;
      border-radius: 0.08rem;
      font-size: 0.29rem;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #FFFFFF;
      vertical-align: middle;
    }
  }

  .pdflag {
    span {
      background: #549FFF;
    }
  }
}

.list {
  margin: 0.24rem 0.32rem 0.24rem 0.32rem;
}

.product {
  margin-bottom: 25px;
  border-radius: 0.16rem;
}

.producttop {
  background: #fff;
  display: flex;
  height: 100px;
  line-height: 100px;
  border-top-left-radius: 0.16rem;
  border-top-right-radius: 0.16rem;
}

.producttopl {
  width: 80%;
  display: flex;
  align-items: center;
}

.producttopl img {
  border: 1px solid #F1DEE4;
  margin: 0 30px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.producttopr {
  width: 20%;
  text-align: center;
}

.producttopr p {
  margin-right: 30px;
  text-align: right;
  // display: flex;
}

.pdfp>p>span {
  color: #4D9AFC;
}

.producttopr span {
  // display: flex;
  // align-items: center;
  font-size: 0.35rem;
  color: #FF7326;
  font-weight: bold;
}

.producttopr img {
  width: 15px;
  height: 15px;
}

// .producttopr .succe {
// color: #00ae28;
// }
.productcen {
  background: #fff;
  padding-bottom: 30px;
  width: 72.5%;
}

.productcon {
  background: #fff;
  display: flex;
  height: 60px;
  line-height: 60px;
}

// .productconl {
// width: 50%;
// }
.orderContent {
  background: #fff;
  display: flex;
}

.lookOrder {
  width: 2.27rem;
  height: 0.67rem;
  line-height: 0.67rem;
  border-radius: 0.33rem;
  border: 1px solid #FF7E3A;
  font-size: 0.32rem;
  font-weight: 500;
  color: #FF7E3A;
  text-align: center;
  margin-top: 17%;
  letter-spacing: 1px;
}

// .lookOrderPd{
// margin-top: 31%;
// }
.lookhb {
  width: 2.35rem;
}

.lookpd {
  border: 1px solid #4D9AFC;
  color: #4D9AFC;
  margin-top: 24%;
}

.lookpdfp {
  margin-top: 31%;
}

.productconl p {
  font-size: 0.32rem;
  color: #535353;
  margin-left: 30px;
}

.productconr {
  width: 50%;
}

.productconr p {
  font-size: 37px;
  color: #514E51;
  float: right;
  margin-right: 30px;
}

.productbom {
  background: #fff;
  // display: flex;
  // height: 150px;
  // line-height: 150px;
  border-top: 1px solid #EEEEEE;
  padding: 0 0.29rem 0.19rem 0.29rem;
  border-bottom-left-radius: 0.16rem;
  border-bottom-right-radius: 0.16rem;

  .productbomFrist {
    display: flex;
    align-items: center;
  }

  .productbomInfo {
    font-size: 0.37rem;
    margin-left: 0.03rem;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: #FF7E3A;
    line-height: 0.45rem;
  }
}

.totalMount {
  padding: 0.25rem 0 0.1rem 0;
  display: flex;
  width: 50%;

  .payTime {
    font-weight: 0.29rem;
    color: #FF7E3A;
    font-weight: 500;
  }
}

.productboml {
  // margin-left: 30px;
  flex: 1;
  // text-align: right;
}

.productbomr {
  // width: 25%;
  flex: 1;
  align-items: center;
  text-align: right;
  // padding-right: 0.3rem;
}

.deleteshow {
  // margin-left:42%
}

.producttg {
  width: 40%;
  text-align: center;
}

.productbom /deep/ .md-button.small {
  height: 100%;
  text-align: -webkit-right;
}

.productbomr /deep/ .md-button-inner {
  margin-left: 1px;
  width: 2.27rem;
  height: 0.67rem;
  // border: 1px solid #676767;
  font-size: 0.32rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 0.33rem;
  background: #FF7E3A;
}

.fpsq /deep/ .md-button-inner {
  background: #549FFF;
}

.productbomr /deep/ .md-button-content {
  font-size: 0.32rem;
}

.payMony {
  width: 2.27rem;
  height: 0.67rem;
  line-height: 0.67rem;
  border-radius: 0.33rem;
  background: #FF7E3A;
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  margin-left: 0.35rem;
}

.showBtn {
  display: flex;
  padding: 0.3rem 0 0.1rem 0;
  width: 60%;
  justify-content: flex-end;
  // padding-bottom: 0.32rem;
}

.wfont {
  color: #3f3f3f;
  font-family: PingFang-SC-Regular;
  font-weight: 400;
  font-size: 0.32rem;

  .mondyUnit {
    font-size: 0.27rem;
    font-family: PingFang-SC-bold;
    font-weight: bold;
    color: #242424;
  }
}

.hfont {
  color: #242424;
  font-size: 0.48rem;
  font-family: PingFang-SC-bold;
  font-weight: bold;
  // font-weight: 400;
}

/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}

.tabBar {
  // position: fixed;
  // top: 0;
  width: 100%;
  background: #ffffff;
}

.isActive {
  background: #FF7326;
  color: #fff;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
}

.lookfp {
  margin-top: 24%;
}
.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 4rem;
    height: 4rem;
  }
}
</style>
