<template>
  <div class="headContent">
    <div >
      <!-- <select @change="select($event)" class="selectBox">
        <template v-for="(item, index) in selectList" :value="item.key">
             <option>{{item.value}}</option>
        </template>
      </select>-->
      <div class="selectBox" @click="showSelector">
        {{selectorValue}}
        <i class="down-triangle"></i>
      </div>
      <md-selector
        v-model="isSelectorShow"
        default-value="2"
        :data="selectList"
        @choose="onSelectorChoose"
        title="请选择"
        large-radius
      ></md-selector>
    </div>
    <div class="inputWrite">
      <img src="../assets/xsqd/ic_search.png" class="searchIcon" />
      <input type="text" v-model="inputValue" placeholder="请输入关键词查询" clearable/>
    </div>
    <div class="searchBtn" @click="search">搜索</div>
  </div>
</template>
<script>
import { Selector } from "mand-mobile";
export default {
  name: "selectDemo",
  components: {
    [Selector.name]: Selector
  },
  props: {
    selectList: Array,
    default: "",
    inputKey:String,
    selectKey:String,
  },
  data() {
    return {
      inputValue:this.inputKey,
      selectIndex: "",
      isSelectorShow: "",
      isSelectorShow: false,
      selectorValue:this.selectKey,
    };
  },
  methods: {
    showSelector() {
      this.isSelectorShow = true
    },
    onSelectorChoose({value,text}) {
      this.selectorValue = text
      this.selectIndex=value
      this.inputValue=""
    },
    search() {
      console.log(this.selectIndex,this.selectorValue,this.inputValue)
      this.$emit("clickSearch",this.selectIndex, this.selectorValue,this.inputValue);
    }
  }
};
</script>
<style scoped>
.headContent {
  display: flex;
  height: 1.31rem;
  background: #ffffff;
  justify-content: center;
  align-items: center;
}
.headContent > div {
  margin-right: 0.1rem;
}
.selectBox {
  width: 2.85rem;
  height: 0.75rem;
  border: 1px solid #b4b4b4;
  border-radius: 0.08rem;
  padding: 0.1rem 0.2rem;
  position: relative;
  font-size: 0.35rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #555555;
}
.down-triangle{
    position: absolute;
    right: 0.2rem;
    top:0.3rem;
    width:0px;
    height:0px;
    border-top:0.08rem solid #404040; 
    border-left:0.12rem solid transparent;
    border-right:0.12rem solid transparent; 
    }
.inputWrite {
  width: 5.01rem;
  height: 0.75rem;
  border: 1px solid #b4b4b4;
  border-radius: 0.08rem;
  margin-left: 0.39rem;
  overflow: hidden;
}
.inputWrite > input {
  height: 0.7rem;
  border-radius: 0.08rem;
  width: 4.26rem;
  font-size: 0.35rem;
  vertical-align: middle;
  border: none;
  margin-left: 0.1rem;
}
input::placeholder {
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #b6b6b6;
}
/* .selectBox,.inputWrite>input{
  height: 0.75rem;
  border: 1px solid #999;
} */
select:focus,
input:focus {
  outline: none;
}

.searchIcon {
  margin-left: 0.12rem;
  width: 0.45rem;
  height: 0.45rem;
  vertical-align: middle;
}
.searchBtn{
  margin-left: 0.1rem;
  margin-right: 0;
  font-size: 0.4rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  color: #333333;
}
/* .inputWrite>input{
  padding: 4px;
  width:120px;
  border-radius: 5px;
} */
</style>